/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { familyRelationship } from './familyRelationship';

export type response_preview_taxReturn2 = {
    era_year: number;
    basic_information: {
        address: string;
        business_address: string;
        business_name: string;
        name_kana: string;
        name: string;
        reference_number: string;
    };
    income_details: Array<{
        income_type: string;
        income_item: string;
        payer_address: string;
        payer_name: string;
        revenue_amount: number;
        withholding_amount: number;
    }>;
    withholding_amount_total: number;
    general_transfer_and_temporary_incomes: Array<{
        income_type: '譲渡（短期）' | '譲渡（長期）';
        revenue_amount: number;
        expense_amount: number;
        deducted_amount: number;
    }>;
    special_situation: string;
    social_insurances_and_small_business_mutual_aids: Array<{
        name: string;
        amount: number;
        other_than_year_end_adjustment: number;
    }>;
    life_insurances: {
        life_insurance_total_amount_latest: number;
        life_insurance_amount_other_than_year_end_adjustment_latest: number;
        life_insurance_total_amount_oldest: number;
        life_insurance_amount_other_than_year_end_adjustment_oldest: number;
        personal_pension_insurance_total_amount_latest: number;
        personal_pension_insurance_amount_other_than_year_end_adjustment_latest: number;
        personal_pension_insurance_total_amount_oldest: number;
        personal_pension_insurance_amount_other_than_year_end_adjustment_oldest: number;
        nursing_insurance_total_amount: number;
        nursing_insurance_amount_other_than_year_end_adjustment: number;
    };
    earthquake_insurances: {
        earthquake_insurance_total_amount: number;
        earthquake_insurance_amount_other_than_year_end_adjustment: number;
        long_term_accident_insurance_total_amount_oldest: number;
        long_term_accident_insurance_amount_other_than_year_end_adjustment_oldest: number;
    };
    donation: {
        recipient_address: string;
        recipient_name: string;
        amount: number;
    } | null;
    is_widow: boolean;
    /**
     * bereaved: 死別、
     * divorced: 離婚、
     * dead_or_alive: 生死不明、
     * not_returned: 未帰還
     */
    widow_reason: response_preview_taxReturn2.widow_reason | null;
    is_single_parent: boolean;
    is_working_student: boolean;
    is_vocational_school_student: boolean;
    disabled: boolean;
    specially_disabled: boolean;
    spouse: {
        id: number;
        name: string;
        birthday: string;
        birthday_era: number;
        birthday_era_year: number;
        disabled: boolean;
        specially_disabled: boolean;
        is_together: boolean;
        is_separately: boolean;
        is_other_adjustment: boolean;
        is_dependent: boolean;
    } | null;
    families: Array<{
        id: number;
        name: string;
        /**
         * child: 子、
         * father: 父、
         * mother: 母、
         * grandfather: 祖父、
         * grandmother: 祖母、
         * grandchild: 孫、
         * other: その他
         */
        relationship: 'child' | 'father' | 'mother' | 'grandfather' | 'grandmother' | 'grandchild' | 'other';
        birthday: string;
        birthday_era: number;
        birthday_era_year: number;
        disabled: boolean;
        specially_disabled: boolean;
        is_under_16: boolean;
        is_separately: boolean;
        is_other_adjustment: boolean;
    }>;
    separated_person_name: string;
    separated_person_address: string;
    inhabitant_tax: {
        dividend_residential_tax_amount: number;
        transfer_residential_tax_amount: number;
        is_declaration_exempt_for_stock_income: boolean;
        payment_method: response_preview_taxReturn2.payment_method | null;
        donation_amount_to_prefecture_and_city: number;
        donation_amount_to_others: number;
        donation_amount_specified_in_prefecture: number;
        donation_amount_specified_in_city: number;
    };
    retired_family: {
        id: number;
        name: string;
        relationship: familyRelationship;
        birthday: string;
        birthday_era: number;
        birthday_era_year: number;
        exc_retirement_income_amount: number;
        disabled: boolean;
        specially_disabled: boolean;
        is_other_adjustment: boolean;
        is_widows: boolean;
        is_single_parent: boolean;
    } | null;
    business_tax: {
        non_taxable_income_number: number | null;
        non_taxable_income_amount: number | null;
        pre_year_opening_closing_division: response_preview_taxReturn2.pre_year_opening_closing_division | null;
        pre_year_opening_closing_date: string | null;
        has_other_prefecture_office: boolean;
    };
    tax_accountant: {
        name: string;
        /**
         * ハイフン区切り
         */
        tel: string;
        will_submit_article_30: boolean;
        will_submit_article_33_2: boolean;
    } | null;
};

export namespace response_preview_taxReturn2 {

    /**
     * bereaved: 死別、
     * divorced: 離婚、
     * dead_or_alive: 生死不明、
     * not_returned: 未帰還
     */
    export enum widow_reason {
        BEREAVED = 'bereaved',
        DIVORCED = 'divorced',
        DEAD_OR_ALIVE = 'dead_or_alive',
        NOT_RETURNED = 'not_returned',
    }

    export enum payment_method {
        DEDUCTION = 'deduction',
        SELF = 'self',
    }

    export enum pre_year_opening_closing_division {
        OPENING = 'opening',
        CLOSING = 'closing',
    }


}

